<template>
  <div class="pageDetails">
    <div class="page">
      <div class="create" style="padding-left: 20px">
        <!-- <el-button plain type="normal" size="mini" @click="close"
        >返回
        </el-button
        > -->
        <el-button plain type="primary" size="mini" @click="print"
        >打印工单
        </el-button
        >
      </div>
      <el-row class="outer-row" id="print">
        <el-col :span="8" style="padding-left: 20px">
          <div class="title" style="font-size: 16px;font-weight: bolder;margin-bottom: 20px">用户信息</div>
          <div class="pdl20div">
            <el-descriptions  :column="1">
              <el-descriptions-item label="用户名称">{{
                  form.user.userName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="联系人">{{
                  form.user.contact
                }}
              </el-descriptions-item>
              <el-descriptions-item label="电话">{{
                  form.user.userPhone
                }}
              </el-descriptions-item>
              <el-descriptions-item label="身份证号">{{
                  form.user.userIdnumber
                }}
              </el-descriptions-item>
              <el-descriptions-item label="营业执照号">{{
                  form.user.businessLicenseNumber
                }}
              </el-descriptions-item>
              <el-descriptions-item label="用户分类">{{
                  form.user.userTypeName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="片区">{{
                  form.user.addrName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="地址">{{
                  form.user.address
                }}
              </el-descriptions-item>
              <el-descriptions-item label="负责部门">{{
                  form.user.deptName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="表具">{{
                  form.user.tableCount
                }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="right-part">
            <div class="title">安检情况</div>
            <div class="right-content">
              <div
                  class="item"
                  v-for="(item, index) in form.datas"
                  :key="index"
                  :style="{borderBottom:index+1!=form.datas.length ? '1px solid #DCDFE6' : (item.safetyResults == 1 ? '0px' : '1px solid #DCDFE6'),
                  marginBottom:index+1!=form.datas.length ? '10px' : '0px'}"
              >
                <div class="first-line" >
                  <div>
                    <span class="left">检查时间：</span>
                    {{ item.checkTime }}
                  </div>
                  <div>
                    <span class="left">安检员：</span>
                    {{ item.inspectorName }}
                  </div>
                  <div>
                    <span class="left">检查时长：</span>
                    {{ item.inspectionTime }}
                  </div>
                  <div>
                    <span class="left">安检结果：</span>
                    {{ item.safetyResultsName }}
                    <el-popover
                        width="300"
                        @show="
												detailAddress(
													item.location,
													'container' + index
												)
											"
                    >
                      <div
                          :id="'container' + index"
                          style="
													height: 300px;
													width: 300px;
												"
                      ></div>
                      <el-button
                          type="text"
                          slot="reference"
                      >打卡位置
                      </el-button
                      >
                    </el-popover>
                  </div>
                </div>
                <div class="second-line" v-if="item.url" style="margin-left:12px">
                  <el-image
                  v-for="(img,i) in item.url.split(',')"
                  :key="i"
                  style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;"
                  :src="img"
                  :preview-src-list="item.url.split(',')"
                >
                </el-image>
                <!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
                  <!-- <img
                      style="width: 100px; height: 100px"
                      :src="fifth"
                      v-for="(fifth, int5) in item.url.split(
											','
										)"
                      @click="showImg(fifth)"
                      :key="int5"
                      alt=""
                  /> -->
                </div>
                <!-- <el-divider></el-divider> -->
              </div>
              <!-- 信息采集 -->
              <div
                  class="infoCollect"
                  style="border-top:0px"
                  v-if="
									form.datas && form.datas[form.datas.length - 1]
										.safetyResults == 1
								"
              >
                <div class="titleTwo">信息采集</div>
                <!--  -->
                <el-collapse accordion >
                  <el-collapse-item
                      :title="'表编号：' + el.sheetSteel"
                      v-for="(el, first) in form.table"
                      :key="first"
                      :name="first"
                  >
                    <div>
                      <div class="item-title">
                        表具信息
                      </div>
                      <el-descriptions >
                        <el-descriptions-item
                            label="燃气表类型"
                        >{{
                            el.tableTypeName
                          }}
                        </el-descriptions-item
                        >
                        <el-descriptions-item
                            label="表向"
                        >{{
                            el.tableDirectionName
                          }}
                        </el-descriptions-item
                        >
                        <el-descriptions-item
                            label="燃气表厂家"
                        >{{
                            el.tableVenderName
                          }}
                        </el-descriptions-item
                        >
                        <el-descriptions-item
                            label="表封号"
                        >{{
                            el.tableTitles
                          }}
                        </el-descriptions-item
                        >
                        <el-descriptions-item
                            label="开户日期"
                        >{{
                            el.openDate
                          }}
                        </el-descriptions-item
                        >
                        <el-descriptions-item
                            label="所接炉具"
                        >{{
                            el.receiveStove
                          }}
                        </el-descriptions-item
                        >
                        <el-descriptions-item
                            label="所购气量"
                        >{{
                            el.purchasedVolume
                          }}
                        </el-descriptions-item
                        >
                        <el-descriptions-item
                            label="最近购气日期"
                        >{{
                            el.recentlyDate
                          }}
                        </el-descriptions-item
                        >
                        <el-descriptions-item
                            label="启用状态"
                        >{{
                            el.tableTypeName
                          }}
                        </el-descriptions-item
                        >
                      </el-descriptions>
                    </div>
                    <el-divider></el-divider>
                    <div class="info">
                      <div class="item-title" style="margin-bottom:10px">
                        采集信息
                      </div>
                      <el-descriptions :column="1" v-for="(item1, second) in el.item" :key="second" direction="vertical">
                        <el-descriptions-item :label="'● '+item1.itemName" :label-style="{width:'120px',color:'#444444',fontWeight: 'bold',borderBottom:'8px'}">
                          <template v-if="item1.inputType == 1 || item1.inputType == 5" >
                            {{item1.note}}
                          </template>
                          <div v-if=" item1.inputType == 2 || item1.inputType == 3 " >
                            <div v-for="item2 in item1.items" :key=" el.tableId + 'b' + item2.items " style="position: relative">
                              <template v-if=" item2.isPitchUp " >
                                <div class="isPitchUpImg">
                                <img class=" icon-img " :src=" item2.type == 1 ? require('../../../assets/normal1.png') : require('../../../assets/warning1.png') " />
                                {{item2.content}}
                                </div>
                                <div class=" describ " v-if=" item2.note "  style=" min-width: 180px; " >
                                  {{ item2.note }}
                                </div>
                                <div class=" imgContain " v-if=" item2.url "  >
                                  <el-image
                                  v-for="(url,index6) in item2.url.split(',')" :key="index6"
                                  style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;"
                                  :src="url"
                                  :preview-src-list="item2.url.split(',')">
                                </el-image>
                                <!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
                                  <!-- <img style=" width: 100px; height: 100px; " :src=" fourths " v-for="( fourths, int4 ) in item2.url.split( ',' )" @click=" showImg( fourths ) "
                                      :key="
																			int4
																		"
                                      alt=""
                                  /> -->
                                </div>

                              </template>
                              <!-- 关联项 -->
                              <!-- <div style="color:#909399">采集关联项：</div> style="margin-left:-6px; "-->
                              <template v-for="item3 in item2.junior">
                                  <div
                                    v-if="item2.isPitchUp && item3.isPitchUp"
                                      
                                      :key="
                                        el.tableId +
                                        item3.juniorId
                                      "
                                  >
                                  <!-- :column="
                                          1
                                        "
                                        v-for="item3 in item2.junior"
                                        :key="
                                          el.tableId +
                                          item3.juniorId
                                        " -->
                                    <el-descriptions direction="vertical">
                                      <el-descriptions-item
                                          :label-style="{color:'#444444',fontWeight: 'bold'}"
                                          :label="'● '+item3.juniorName"
                                      >
                                        <template  v-if="item3.juniorInputType == 1 || item3.juniorInputType == 5">
                                          {{item3.note }}
                                        </template>
                                        <div  v-if="item3.juniorInputType ==2 ||item3.juniorInputType ==3">
                                          <div
                                              v-for="item4 in item3.juniors"
                                              :key="
                                                el.tableId +
                                                'b' +
                                                item4.juniorsId
                                              "
                                          >
                                            <template v-if="item4.isPitchUp">
                                              <div class="isPitchUpImg">
                                              <img
                                                  class="icon-img"
                                                  :src="item4.juniorsType ==1
                                                      ? require('../../../assets/normal1.png')
                                                      : require('../../../assets/warning1.png')"
                                              />
                                              {{item4.juniorsContent}}

                                              </div>
                                              <div
                                                  class="describ"
                                                  v-if="item4.note"
                                                  style="min-width: 180px;"
                                              >
                                                {{
                                                  item4.note
                                                }}
                                              </div>
                                              <div class="imgContain" v-if="item4.url" >
                                                <el-image
                                                v-for="(url,index5) in item4.url.split(',')" :key="index5"
                                                style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;"
                                                :src="url"
                                                :preview-src-list="item4.url.split(',')">
                                              </el-image>
                                              <!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
                                              </div>
                                            </template>
                                          </div>
                                        </div>
                                        <div  v-if="item3.juniorInputType ==4">
                                          <div class="imgContain" v-if="item3.url">
                                            <el-image
                                            v-for="(url,index4) in item3.url.split(',')" :key="index4"
                                            style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;"
                                            :src="url"
                                            :preview-src-list="item3.url.split(',')">
                                          </el-image>
                                          <!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
                                          </div>
                                        </div>
                                      </el-descriptions-item>
                                    </el-descriptions>
                                  </div>
                                  
                                  </template>
                            </div>
                          </div>
                          <div class="right" v-if="item1.inputType == 4">
                            <div class="imgContain" v-if="item1.url" >
                              <el-image
                                v-for="(url,index3) in item1.url.split(',')" :key="index3"
                                style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;"
                                :src="url"
                                :preview-src-list="item1.url.split(',')">
                              </el-image>
                              <!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
                              <!-- <img style=" width: 100px; height: 100px; " :src="thirdlys"  v-for="( thirdlys, int2 ) in item1.url.split( ',' )" @click=" showImg( thirdlys ) " :key="int2" alt="" /> -->
                            </div>
                          </div>
                        </el-descriptions-item>
                      </el-descriptions>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <!-- 用户确认 -->
              <div
                  class="userConfirm"
                  
                  v-if="
									form.datas && form.datas[form.datas.length - 1]
										.safetyResults == 1
								"
              >
                <div class="left">用户确认：</div>
                <div class="imgContain"  style="margin-left: -5px;">
                  <!-- <el-image
                 v-if="form.userUrl"
                  style="width: 100px; height: 100px"
                  :src="form.userUrl.split(',')[0]" 
                  :preview-src-list="form.userUrl.split(',')">
                </el-image> -->
                  <!-- <div v-if="form.userSignature"> -->
                    <!-- <img
                        style="width: 100px; height: 100px"
                        :src="items"
                        v-for="(
												items, int
											) in form.userSignature.split(',')"
                        :key="int"
                        alt=""
                        @click="showImg(items)"
                    /> -->
                    <template v-if="form.userSignature">
                    <el-image
                      v-for="(url,index2) in form.userSignature.split(',')" :key="index2"
                      style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;"
                      :src="url"
                      :preview-src-list="form.userSignature.split(',')">
                    </el-image>
                    <!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
                    </template>
                    <el-image
                        v-for="(url,index1) in form.userUrl.split(',')" :key="index1"
                        style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;"
                        :src="url"
                        :preview-src-list="form.userUrl.split(',')">
                    </el-image>
                    <!-- <el-image :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" :preview-src-list="[require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" class='imgstyle'></el-image> -->
                  <!-- </div> -->
                </div>
              </div>
              </div>
              
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- <show-img :imgList="imgList" ref="imgList"></show-img> -->
    </div>
  </div>
</template>

<script>
import {dataParticulars} from "@/RequestPort/workorder/maintenRecord";
// import showImg from "@/components/showImg.vue";

export default {
  name: "unresidentDetail",
  // components: {showImg},
  data() {
    return {
      activeNames: 0,
      srcList: [],
      // 表单
      form: {
        user: {},
      },
      dataId: "",
      // 图片
      imgList: [],
      garyimg: require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`),
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.dataId = this.$route.query.id;
      this.particulars(this.$route.query.id);
    }
  },
  methods: {
    particulars(val) {
      dataParticulars({dataId: val}).then((res) => {
        this.form = {...this.form, ...res.data};
      });
    },
    print() {
      //     let newWindow=window.open("打印窗口","_blank");
      //     let docStr = document.getElementById('print').innerHTML
      //     // var docStr = obj.innerHTML;

      // newWindow.document.write(docStr);

      // newWindow.document.close();

      // newWindow.print();
      //  window.print(aaa)
      this.$router.push({
        path: "/detailsPrint",
        query: {id: this.dataId},
      });
    },
    /**
     * 图片地址放大
     */
    // showImg(file) {
    //   if (file) {
    //     this.$refs.imgList.setdialogVisible(true);
    //     this.imgList = [file];
    //   } else {
    //     this.$message.error("暂无文件");
    //   }
    // },
    detailAddress(e, index) {
      // this.$refs.mapposition.setMapCenter({
      //   checkinLocation: e
      // })
      if (e) {
        console.log(index);
        let map = new AMap.Map(index, {
          zoom: 15, //级别
          center: [Number(e.split(" ")[0]), Number(e.split(" ")[1])],
        });
        let marker = new AMap.Marker({
          position: [
            Number(e.split(" ")[0]),
            Number(e.split(" ")[1]),
          ], //位置
        });
        map.add(marker); //添加到地图
      } else {
        this.$message.error("无打卡位置信息");
      }
    },
    close() {
      this.$router.back(-1);
    },
  },
};
</script>

<style scoped lang="less">
.pageDetails {
  padding: 10px;
  width: 100%;
  background: #f3f4f7;
}

.page {
  // height: 100%;
  padding: 10px;
  height: calc(100vh - 91px);
  overflow: auto;
  background: #fff;

  box-sizing: border-box;

  .pdl20div {
    ::v-deep.el-descriptions {
      .el-descriptions__body {
        padding-left: 20px;
      }
    }
  }

  ::v-deep {
    .el-form-item {
      margin-bottom: 0;
    }

    .colsite {
      margin-bottom: 20px;
    }
  }
// 采集信息
.info{
  // /deep/.el-descriptions-item__content{
  //   padding:0 0 19px 0px;
  // }
  /deep/.el-collapse-item__content{
    padding-bottom: 0px;
  }
}
  .create {
    width: 100%;
    height: 50px;
    // background-color: burlywood;
    display: flex;
    flex-direction: row;
    align-items: center;

    .create_size {
      font-size: 20px;
      font-weight: 500;
      color: #252700;
      margin-left: 20px;
    }
  }

  // .box {
  //   width: 96%;
  //   margin: 0 auto;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;


  //   .tableRight {
  //     width: 100%;
  //     margin-top: 5px;
  //     margin-right: 25px;

  //     .btn {
  //       width: 210px;
  //       background: #0f74d5;
  //       border-radius: 4px;
  //       float: right;
  //       margin-right: 28%;
  //     }

  //     .title {
  //       padding: 10px;
  //       margin-bottom: 20px;
  //     }

  //     .row {
  //       padding: 12px 30px 0 30px;
  //     }
  //   }
  // }
}

.right-part {
  padding-left: 30px;
  height: calc(100% - 50px);
  overflow: auto;

  .title {
    font-size: 16px;
    font-weight: 700;
  }
  .titleTwo{
    font-weight: 400;
    color: #303133;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .right-content {
    padding: 30px;

    .first-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      // border: 1px solid #DCDFE6;
      padding: 17px 17px 10px;
      div {
        width: 50%;
        margin-bottom: 19px;

        > span {
          color: #545454;
        }

        button {
          padding: 0;
          margin-left: 40px;
        }
      }
      .left{
        color: #909399;
      }
    }
    .item{
      border: 1px solid #DCDFE6;
    }
  }
/deep/.el-collapse-item__header{
   background-color: #F6F6F6;
  padding: 0 21px;
}
/deep/.el-collapse-item__wrap{
  padding: 0 21px;
}
/deep/.el-divider{
  margin: 0 0 19px 0;
  width: 100%;
}
  .infoCollect {
    border: 1px solid #ececec;
    padding:0 17px 33px 17px;

    // .item-title::before {
    //   content: "| ";
    //   color: #7cbaff;
    // }
    /deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell{
		padding-bottom: 5px;
	}
    .item-title{
      background: linear-gradient(90deg, #D7E7FF 0%, #D7E7FF 100%);
      font-weight: 400;
      color: #303133;
      margin: 19px 0;
      width: 56px;
      font-size: 14px;
    }
    /deep/ .el-descriptions {
      // padding: 0px 63px;
      
      .el-descriptions-item__label:not(.is-bordered-label) {
        color: #909399;
        // width: 120px;
      }

      .el-descriptions-item__content {
        // display: block;
        color: #303133;
        font-size: 14px;
        .ques1,
        .ques2 {
          line-height: 30px;
          img {
            width: 16px;
            height: 16px;
            vertical-align: text-top;
          }
        }
        .ques2 {
          padding-left: 20px;
        }
      }
    }
  }
  .describ{
    color: #303133;
        font-size: 14px;
            margin-left: 17px;
  }
// /deep/.el-descriptions__body{
//         background-color: transparent;
//       }
  .imgContain {
    margin: 0 14px;
    .el-image {
      margin: 0 10px 10px 0;
    }
  }

  .userConfirm {
    // margin-top: 24px;
    // display: flex;
    margin:24px 0px 0;
    .left {
      min-width: 66px;
      margin-right: 32px;
    }
  }
}

.outer-row {
  height: calc(100% - 50px);

  .el-col {
    height: 100%;
  }

  .el-col:first-child {
    border-right: 1px solid #f1f1f1;
  }
}

// /deep/ .el-descriptions-item__label {
//   width: 70px;
// }

/deep/ .el-descriptions__table {
  tbody {
    .el-descriptions-item {
      padding-bottom: 20px;
    }
  }
}

::v-deep.el-descriptions__title::before,
.title::before {
  content: "| ";
  font-weight: bold;
  color: #1082ff;
}
.icon-img {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 5px;
}

  .describ{
    color: #303133;
        font-size: 14px;
            margin-left: 17px;
  }
  .imgContain{
	margin: 0 14px;
  }
  .isPitchUpImg{
    display: flex;
    justify-content: flex-start;
    align-content: center;
  }
  .imgstyle{
    width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;
  }
</style>